import $ from 'jquery';

/**
 * jQuery.fields() plugin
 *
 * @param {string|undefined} fldvar - Field variable name or undefined
 *  If field variable name, returns jQuery object of the specified field element(s).
 *  If unspecified, returns object of jQuery objects of all fields.
 * @returns jQuery object
 */
$.fn.fields = function(fldvar) { // Note: fldvar has NO "x_" prefix
    var rec = {},
        id = this.attr("id"),
        obj = this[0],
        m = id.match(/^[xy](\d*)_/),
        f, tbl, infix;
    if (m) { // "this" is input element
        f = ew.getForm(obj); // form
        tbl = this.data("table"); // table var
        infix = m[1]; // row index
    } else if (obj && obj.htmlForm) { // "this" is form
        f = obj.$element; // form
        tbl = obj.id.replace(new RegExp("^f|" + obj.pageId + "$", "g"), ""); // table var
        infix = $(obj.htmlForm).data("rowindex"); // row index
    }
    var selector = "[data-table" + (tbl ? "=" + tbl : "") + "][data-field" + (fldvar ? "=x_" + fldvar : "") + "]";
    if ($.isValue(infix))
        selector += "[name^=x" + infix + "_]";
    if (f && selector) {
        $(f).find(selector).each(function() {
            var key = this.getAttribute("data-field").substr(2), name = this.getAttribute("name");
            key = (/^y_/.test(name)) ? "y_" + key : key; // Use "y_fldvar" as key for 2nd search input
            rec[key] = (rec[key]) ? rec[key].add(this) : $(this); // Create jQuery object for each field
        });
    }
    return (fldvar) ? rec[fldvar] : rec;
};
$.fn.extend({
    // Get jQuery object of the row (<div> or <tr>)
    row: function() {
        var $row = this.closest("#r_" + this.data("field")?.substr(2));
        if (!$row[0])
            $row = this.closest(".ew-table > tbody > tr"); // Grid page
        return $row;
    },
    // Show/Hide field
    visible: function(v) {
        var $p = this.closest("#r_" + this.data("field")?.substr(2)); // Find the row
        if (!$p[0])
            $p = this.closest("[id^=el]"); // Find the span
        if (typeof(v) != "undefined") {
            $p.toggle(v);
            return this;
        } else {
            return $el.is(":visible");
        }
    },
    // Get/Set field "readonly" attribute
    // Note: This attribute is ignored if the value of the type attribute is hidden, range, color, checkbox, radio, file, or a button type
    readonly: function(v) {
        if (typeof(v) != "undefined") {
            this.prop("readOnly", v);
            return this;
        } else {
            return this.prop("readOnly");
        }
    },
    // Get/Set field "disabled" attribute
    // Note: A disabled control's value isn't submitted with the form
    disabled: function(v) {
        if (typeof(v) != "undefined") {
            this.prop("disabled", v);
            return this;
        } else {
            return this.prop("disabled");
        }
    },
    // Get/Set field value(s)
    // Note: Return array if select-multiple
    value: function(v) {
        var type = this.attr("type");
        if (typeof(v) != "undefined") {
            if (!Array.isArray(v))
                v = [v];
            var type = this.attr("type");
            var el = (type == "radio" || type == "checkbox") ? this.get() : this[0];
            if (ew.isHiddenTextArea(this)) {
                this.val(v).data("editor").save();
            } else {
                ew.selectOption(el, v);
                if (this.hasClass("select2-hidden-accessible")) { // Select2
                    this.trigger("change");
                }
            }
            return this;
        } else {
            if (type == "checkbox") {
                var val = ew.getOptionValues(this.get());
                return (this.length == 1) ? val.join() : val;
            } else if (type == "radio") {
                return ew.getOptionValues(this.get()).join();
            } else if (ew.isHiddenTextArea(this)) {
                this.data("editor").save();
                return this.val();
            } else {
                return this.val();
            }
        }
    },
    // Get field value as number
    toNumber: function() {
        return ew.parseNumber(this.value());
    },
    // Get field value as moment object
    toDate: function() {
        return ew.parseDate(this.value(), this.data("format"));
    },
    // Get field value as native Date object
    toJsDate: function() {
        return ew.parseDate(this.value(), this.data("format")).toDate();
    }
});